import * as React from 'react';

function SvgEqualizer(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M16 9h4v11h-4zm-6-5h4v16h-4zm-6 8h4v8H4z' />
		</svg>
	);
}

export default SvgEqualizer;
