import * as React from 'react';

function SvgEasel3Fill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.5 12v1.134a1 1 0 11-1 0V12h-5A1.5 1.5 0 011 10.5V3h14v7.5a1.5 1.5 0 01-1.5 1.5h-5zm7-10a.5.5 0 000-1H.5a.5.5 0 000 1h15z' />
		</svg>
	);
}

export default SvgEasel3Fill;
