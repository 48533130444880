import * as React from 'react';

function SvgShift(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M7.27 2.047a1 1 0 011.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v3a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3H1.654C.78 10.5.326 9.455.924 8.816L7.27 2.047zM14.346 9.5L8 2.731 1.654 9.5H4.5a1 1 0 011 1v3h5v-3a1 1 0 011-1h2.846z' />
		</svg>
	);
}

export default SvgShift;
