import * as React from 'react';

function SvgBank2(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.277.084a.5.5 0 00-.554 0l-7.5 5A.5.5 0 00.5 6h1.875v7H1.5a.5.5 0 000 1h13a.5.5 0 100-1h-.875V6H15.5a.5.5 0 00.277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 110-2 1 1 0 010 2zM.5 15a.5.5 0 000 1h15a.5.5 0 100-1H.5z' />
		</svg>
	);
}

export default SvgBank2;
