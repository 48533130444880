import * as React from 'react';

function SvgFan(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M10 3c0 1.313-.304 2.508-.8 3.4a1.991 1.991 0 00-1.484-.38c-.28-.982-.91-2.04-1.838-2.969a8.368 8.368 0 00-.491-.454A5.976 5.976 0 018 2c.691 0 1.355.117 1.973.332.018.219.027.442.027.668zm0 5c0 .073-.004.146-.012.217 1.018-.019 2.2-.353 3.331-1.006a8.39 8.39 0 00.57-.361 6.004 6.004 0 00-2.53-3.823 9.02 9.02 0 01-.145.64c-.34 1.269-.944 2.346-1.656 3.079.277.343.442.78.442 1.254zm-.137.728a2.007 2.007 0 01-1.07 1.109c.525.87 1.405 1.725 2.535 2.377.2.116.402.222.605.317a5.986 5.986 0 002.053-4.111c-.208.073-.421.14-.641.199-1.264.339-2.493.356-3.482.11zM8 10c-.45 0-.866-.149-1.2-.4-.494.89-.796 2.082-.796 3.391 0 .23.01.457.027.678A5.99 5.99 0 008 14c.94 0 1.83-.216 2.623-.602a8.359 8.359 0 01-.497-.458c-.925-.926-1.555-1.981-1.836-2.96-.094.013-.191.02-.29.02zM6 8c0-.08.005-.16.014-.239-1.02.017-2.205.351-3.34 1.007a8.366 8.366 0 00-.568.359 6.003 6.003 0 002.525 3.839 8.37 8.37 0 01.148-.653c.34-1.267.94-2.342 1.65-3.075A1.988 1.988 0 016 8zm-3.347-.632c1.267-.34 2.498-.355 3.488-.107.196-.494.583-.89 1.07-1.1-.524-.874-1.406-1.733-2.541-2.388a8.363 8.363 0 00-.594-.312 5.987 5.987 0 00-2.06 4.106c.206-.074.418-.14.637-.199zM8 9a1 1 0 100-2 1 1 0 000 2z' />
			<path d='M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z' />
		</svg>
	);
}

export default SvgFan;
