import * as React from 'react';

function SvgStop(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M8 8h8v8H8z' opacity={0.3} />
			<path d='M6 18h12V6H6v12zM8 8h8v8H8V8z' />
		</svg>
	);
}

export default SvgStop;
