import * as React from 'react';

function SvgEMobiledata(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M16 9V7H8v10h8v-2h-6v-2h6v-2h-6V9h6z' />
		</svg>
	);
}

export default SvgEMobiledata;
