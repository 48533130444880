import * as React from 'react';

function SvgMagic(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M9.5 2.672a.5.5 0 101 0V.843a.5.5 0 00-1 0v1.829zm4.5.035A.5.5 0 0013.293 2L12 3.293a.5.5 0 10.707.707L14 2.707zM7.293 4A.5.5 0 108 3.293L6.707 2A.5.5 0 006 2.707L7.293 4zm-.621 2.5a.5.5 0 100-1H4.843a.5.5 0 100 1h1.829zm8.485 0a.5.5 0 100-1h-1.829a.5.5 0 000 1h1.829zM13.293 10A.5.5 0 1014 9.293L12.707 8a.5.5 0 10-.707.707L13.293 10zM9.5 11.157a.5.5 0 001 0V9.328a.5.5 0 00-1 0v1.829zm1.854-5.097a.5.5 0 000-.706l-.708-.708a.5.5 0 00-.707 0L8.646 5.94a.5.5 0 000 .707l.708.708a.5.5 0 00.707 0l1.293-1.293zm-3 3a.5.5 0 000-.706l-.708-.708a.5.5 0 00-.707 0L.646 13.94a.5.5 0 000 .707l.708.708a.5.5 0 00.707 0L8.354 9.06z' />
		</svg>
	);
}

export default SvgMagic;
