import * as React from 'react';

function SvgEasel3(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path fillRule='evenodd' d='M8.5 13.134V12h5a1.5 1.5 0 001.5-1.5V2h.5a.5.5 0 000-1H.5a.5.5 0 000 1H1v8.5A1.5 1.5 0 002.5 12h5v1.134a1 1 0 101 0zM2 2v8.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V2H2z' />
		</svg>
	);
}

export default SvgEasel3;
