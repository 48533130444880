import * as React from 'react';

function SvgCloudRainHeavyFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M4.176 11.032a.5.5 0 01.292.643l-1.5 4a.5.5 0 01-.936-.35l1.5-4a.5.5 0 01.644-.293zm3 0a.5.5 0 01.292.643l-1.5 4a.5.5 0 01-.936-.35l1.5-4a.5.5 0 01.644-.293zm3 0a.5.5 0 01.292.643l-1.5 4a.5.5 0 01-.936-.35l1.5-4a.5.5 0 01.644-.293zm3 0a.5.5 0 01.292.643l-1.5 4a.5.5 0 01-.936-.35l1.5-4a.5.5 0 01.644-.293zm.229-7.005a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10H13a3 3 0 00.405-5.973z' />
		</svg>
	);
}

export default SvgCloudRainHeavyFill;
