import * as React from 'react';

function SvgFileTextFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zM5 4h6a.5.5 0 010 1H5a.5.5 0 010-1zm-.5 2.5A.5.5 0 015 6h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5zM5 8h6a.5.5 0 010 1H5a.5.5 0 010-1zm0 2h3a.5.5 0 010 1H5a.5.5 0 010-1z' />
		</svg>
	);
}

export default SvgFileTextFill;
