import * as React from 'react';

function SvgCrop(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M3.5.5A.5.5 0 014 1v13h13a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2H3.5a.5.5 0 01-.5-.5V4H1a.5.5 0 010-1h2V1a.5.5 0 01.5-.5zm2.5 3a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v8a.5.5 0 01-1 0V4H6.5a.5.5 0 01-.5-.5z' />
		</svg>
	);
}

export default SvgCrop;
