import * as React from 'react';

function SvgCheck2Square(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M3 14.5A1.5 1.5 0 011.5 13V3A1.5 1.5 0 013 1.5h8a.5.5 0 010 1H3a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V8a.5.5 0 011 0v5a1.5 1.5 0 01-1.5 1.5H3z' />
			<path d='M8.354 10.354l7-7a.5.5 0 00-.708-.708L8 9.293 5.354 6.646a.5.5 0 10-.708.708l3 3a.5.5 0 00.708 0z' />
		</svg>
	);
}

export default SvgCheck2Square;
