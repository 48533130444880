import * as React from 'react';

function SvgFlower2(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8 16a4 4 0 004-4 4 4 0 000-8 4 4 0 00-8 0 4 4 0 100 8 4 4 0 004 4zm3-12c0 .073-.01.155-.03.247-.544.241-1.091.638-1.598 1.084A2.987 2.987 0 008 5c-.494 0-.96.12-1.372.331-.507-.446-1.054-.843-1.597-1.084A1.117 1.117 0 015 4a3 3 0 016 0zm-.812 6.052A2.99 2.99 0 0011 8a2.99 2.99 0 00-.812-2.052c.215-.18.432-.346.647-.487C11.34 5.131 11.732 5 12 5a3 3 0 110 6c-.268 0-.66-.13-1.165-.461a6.833 6.833 0 01-.647-.487zm-3.56.617a3.001 3.001 0 002.744 0c.507.446 1.054.842 1.598 1.084.02.091.03.174.03.247a3 3 0 11-6 0c0-.073.01-.155.03-.247.544-.242 1.091-.638 1.598-1.084zm-.816-4.721A2.99 2.99 0 005 8c0 .794.308 1.516.812 2.052a6.83 6.83 0 01-.647.487C4.66 10.869 4.268 11 4 11a3 3 0 010-6c.268 0 .66.13 1.165.461.215.141.432.306.647.487zM8 9a1 1 0 110-2 1 1 0 010 2z' />
		</svg>
	);
}

export default SvgFlower2;
