import * as React from 'react';

function SvgCreditCard2BackFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M0 4a2 2 0 012-2h12a2 2 0 012 2v5H0V4zm11.5 1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2zM0 11v1a2 2 0 002 2h12a2 2 0 002-2v-1H0z' />
		</svg>
	);
}

export default SvgCreditCard2BackFill;
