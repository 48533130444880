import * as React from 'react';

function SvgChatRightTextFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M16 2a2 2 0 00-2-2H2a2 2 0 00-2 2v8a2 2 0 002 2h9.586a1 1 0 01.707.293l2.853 2.853a.5.5 0 00.854-.353V2zM3.5 3h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 2.5h9a.5.5 0 010 1h-9a.5.5 0 010-1zm0 2.5h5a.5.5 0 010 1h-5a.5.5 0 010-1z' />
		</svg>
	);
}

export default SvgChatRightTextFill;
