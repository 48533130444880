import * as React from 'react';

function SvgPhoneVibrateFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M4 4a2 2 0 012-2h4a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm5 7a1 1 0 10-2 0 1 1 0 002 0zM1.807 4.734a.5.5 0 10-.884-.468A7.967 7.967 0 000 8c0 1.347.334 2.618.923 3.734a.5.5 0 10.884-.468A6.967 6.967 0 011 8c0-1.18.292-2.292.807-3.266zm13.27-.468a.5.5 0 00-.884.468C14.708 5.708 15 6.819 15 8c0 1.18-.292 2.292-.807 3.266a.5.5 0 00.884.468A7.967 7.967 0 0016 8a7.967 7.967 0 00-.923-3.734zM3.34 6.182a.5.5 0 10-.93-.364A5.986 5.986 0 002 8c0 .769.145 1.505.41 2.182a.5.5 0 10.93-.364A4.986 4.986 0 013 8c0-.642.12-1.255.34-1.818zm10.25-.364a.5.5 0 00-.93.364c.22.563.34 1.176.34 1.818 0 .642-.12 1.255-.34 1.818a.5.5 0 00.93.364C13.856 9.505 14 8.769 14 8c0-.769-.145-1.505-.41-2.182z' />
		</svg>
	);
}

export default SvgPhoneVibrateFill;
