import * as React from 'react';

function SvgArrowDownRightSquareFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M14 16a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h12zM5.904 5.197L10 9.293V6.525a.5.5 0 011 0V10.5a.5.5 0 01-.5.5H6.525a.5.5 0 010-1h2.768L5.197 5.904a.5.5 0 01.707-.707z' />
		</svg>
	);
}

export default SvgArrowDownRightSquareFill;
