import * as React from 'react';

function SvgCaretDownSquareFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm4 4a.5.5 0 00-.374.832l4 4.5a.5.5 0 00.748 0l4-4.5A.5.5 0 0012 6H4z' />
		</svg>
	);
}

export default SvgCaretDownSquareFill;
