import * as React from 'react';

function SvgBookmarksFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M2 4a2 2 0 012-2h6a2 2 0 012 2v11.5a.5.5 0 01-.777.416L7 13.101l-4.223 2.815A.5.5 0 012 15.5V4z' />
			<path d='M4.268 1A2 2 0 016 0h6a2 2 0 012 2v11.5a.5.5 0 01-.777.416L13 13.768V2a1 1 0 00-1-1H4.268z' />
		</svg>
	);
}

export default SvgBookmarksFill;
