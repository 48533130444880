import * as React from 'react';

function SvgFileSpreadsheetFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M12 0H4a2 2 0 00-2 2v4h12V2a2 2 0 00-2-2zm2 7h-4v2h4V7zm0 3h-4v2h4v-2zm0 3h-4v3h2a2 2 0 002-2v-1zm-5 3v-3H6v3h3zm-4 0v-3H2v1a2 2 0 002 2h1zm-3-4h3v-2H2v2zm0-3h3V7H2v2zm4 0V7h3v2H6zm0 1h3v2H6v-2z' />
		</svg>
	);
}

export default SvgFileSpreadsheetFill;
