import * as React from 'react';

function SvgClouds(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M16 7.5a2.5 2.5 0 01-1.456 2.272 3.513 3.513 0 00-.65-.824 1.5 1.5 0 00-.789-2.896.5.5 0 01-.627-.421 3 3 0 00-5.22-1.625 5.587 5.587 0 00-1.276.088 4.002 4.002 0 017.392.91A2.5 2.5 0 0116 7.5z' />
			<path d='M7 5a4.5 4.5 0 014.473 4h.027a2.5 2.5 0 010 5H3a3 3 0 01-.247-5.99A4.502 4.502 0 017 5zm3.5 4.5a3.5 3.5 0 00-6.89-.873.5.5 0 01-.51.375A2 2 0 103 13h8.5a1.5 1.5 0 10-.376-2.953.5.5 0 01-.624-.492V9.5z' />
		</svg>
	);
}

export default SvgClouds;
