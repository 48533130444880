import * as React from 'react';

function SvgLayoutTextSidebar(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M3.5 3a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 3a.5.5 0 000 1h5a.5.5 0 000-1h-5zM3 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm.5 2.5a.5.5 0 000 1h5a.5.5 0 000-1h-5z' />
			<path d='M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm12-1v14h2a1 1 0 001-1V2a1 1 0 00-1-1h-2zm-1 0H2a1 1 0 00-1 1v12a1 1 0 001 1h9V1z' />
		</svg>
	);
}

export default SvgLayoutTextSidebar;
