import * as React from 'react';

function SvgToggles(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M4.5 9a3.5 3.5 0 100 7h7a3.5 3.5 0 100-7h-7zm7 6a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm-7-14a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm2.45 0A3.49 3.49 0 018 3.5 3.49 3.49 0 016.95 6h4.55a2.5 2.5 0 000-5H6.95zM4.5 0h7a3.5 3.5 0 110 7h-7a3.5 3.5 0 110-7z' />
		</svg>
	);
}

export default SvgToggles;
