import * as React from 'react';

function SvgPieChart(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M7.5 1.018a7 7 0 00-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 008.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0014.982 8.5zM0 8a8 8 0 1116 0A8 8 0 010 8z' />
		</svg>
	);
}

export default SvgPieChart;
