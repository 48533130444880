import * as React from 'react';

function SvgVoicemail(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M7 8.5A3.49 3.49 0 015.95 11h4.1a3.5 3.5 0 112.45 1h-9A3.5 3.5 0 117 8.5zm-6 0a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm14 0a2.5 2.5 0 10-5 0 2.5 2.5 0 005 0z' />
		</svg>
	);
}

export default SvgVoicemail;
