import * as React from 'react';

function SvgHandIndexThumbFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 011.99 2.199l-.272 2.715a3.5 3.5 0 01-.444 1.389l-1.395 2.441A1.5 1.5 0 0112.42 16H6.118a1.5 1.5 0 01-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 012.373-1.852L5 8.293V1.75a1.75 1.75 0 013.5 0z' />
		</svg>
	);
}

export default SvgHandIndexThumbFill;
