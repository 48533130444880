import * as React from 'react';

function SvgEasel2Fill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.447.276a.5.5 0 00-.894 0L7.19 1H2.5A1.5 1.5 0 001 2.5V10h14V2.5A1.5 1.5 0 0013.5 1H8.809L8.447.276z' />
			<path fillRule='evenodd' d='M.5 11a.5.5 0 000 1h2.86l-.845 3.379a.5.5 0 00.97.242L3.89 14h8.22l.405 1.621a.5.5 0 00.97-.242L12.64 12h2.86a.5.5 0 000-1H.5zm3.64 2l.25-1h7.22l.25 1H4.14z' />
		</svg>
	);
}

export default SvgEasel2Fill;
