import * as React from 'react';

function SvgFilePptFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.188 8.5H7V5h1.188a1.75 1.75 0 110 3.5z' />
			<path d='M4 0h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2zm3 4a1 1 0 00-1 1v6.5a.5.5 0 001 0v-2h1.188a2.75 2.75 0 000-5.5H7z' />
		</svg>
	);
}

export default SvgFilePptFill;
