import * as React from 'react';

function SvgThermometerHalf(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M9.5 12.5a1.5 1.5 0 11-2-1.415V6.5a.5.5 0 011 0v4.585a1.5 1.5 0 011 1.415z' />
			<path d='M5.5 2.5a2.5 2.5 0 015 0v7.55a3.5 3.5 0 11-5 0V2.5zM8 1a1.5 1.5 0 00-1.5 1.5v7.987l-.167.15a2.5 2.5 0 103.333 0l-.166-.15V2.5A1.5 1.5 0 008 1z' />
		</svg>
	);
}

export default SvgThermometerHalf;
