import * as React from 'react';

function SvgCastConnected(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0zm0 0h24v24H0V0z' fill='none' />
			<path d='M17 9H5.95c2.83 1.17 5.15 3.3 6.56 6H17V9z' opacity={0.3} />
			<path d='M1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0-4v2a9 9 0 019 9h2c0-6.08-4.93-11-11-11zm20-7H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM5 7v1.63c.32.1.63.24.95.37H17v6h-4.49c.15.29.29.58.42.88.16.36.31.74.44 1.12H19V7H5z' />
		</svg>
	);
}

export default SvgCastConnected;
