import * as React from 'react';

function SvgSignalCellularConnectedNoInternet0Bar(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M20 18h2v-8h-2v8zm0 4h2v-2h-2v2zm-2-2v2H2L22 2v6h-2V6.83L6.83 20H18z' />
		</svg>
	);
}

export default SvgSignalCellularConnectedNoInternet0Bar;
