import * as React from 'react';

function SvgFingerprint(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.06 6.5a.5.5 0 01.5.5v.776a11.5 11.5 0 01-.552 3.519l-1.331 4.14a.5.5 0 01-.952-.305l1.33-4.141a10.5 10.5 0 00.504-3.213V7a.5.5 0 01.5-.5z' />
			<path d='M6.06 7a2 2 0 114 0 .5.5 0 11-1 0 1 1 0 10-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 016 8.447c.04-.37.06-.742.06-1.115V7zm3.509 1a.5.5 0 01.487.513 11.5 11.5 0 01-.587 3.339l-1.266 3.8a.5.5 0 01-.949-.317l1.267-3.8a10.5 10.5 0 00.535-3.048A.5.5 0 019.569 8zm-3.356 2.115a.5.5 0 01.33.626L5.24 14.939a.5.5 0 11-.955-.296l1.303-4.199a.5.5 0 01.625-.329z' />
			<path d='M4.759 5.833A3.501 3.501 0 0111.559 7a.5.5 0 01-1 0 2.5 2.5 0 00-4.857-.833.5.5 0 11-.943-.334zm.3 1.67a.5.5 0 01.449.546 10.72 10.72 0 01-.4 2.031l-1.222 4.072a.5.5 0 11-.958-.287L4.15 9.793a9.72 9.72 0 00.363-1.842.5.5 0 01.546-.449zm6 .647a.5.5 0 01.5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 01-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 01.5-.5z' />
			<path d='M3.902 4.222a4.996 4.996 0 015.202-2.113.5.5 0 01-.208.979 3.996 3.996 0 00-4.163 1.69.5.5 0 01-.831-.556zm6.72-.955a.5.5 0 01.705-.052A4.99 4.99 0 0113.059 7v1.5a.5.5 0 11-1 0V7a3.99 3.99 0 00-1.386-3.028.5.5 0 01-.051-.705zM3.68 5.842a.5.5 0 01.422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 11-.96-.279L2.8 8.821A6.531 6.531 0 003.058 7c0-.25.019-.496.054-.736a.5.5 0 01.568-.422zm8.882 3.66a.5.5 0 01.456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 01-.941-.338l.745-2.07a10.51 10.51 0 00.584-2.678.5.5 0 01.54-.456z' />
			<path d='M4.81 1.37A6.5 6.5 0 0114.56 7a.5.5 0 11-1 0 5.5 5.5 0 00-8.25-4.765.5.5 0 01-.5-.865zm-.89 1.257a.5.5 0 01.04.706A5.478 5.478 0 002.56 7a.5.5 0 01-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 01.706-.04zM1.915 8.02a.5.5 0 01.346.616l-.779 2.767a.5.5 0 11-.962-.27l.778-2.767a.5.5 0 01.617-.346zm12.15.481a.5.5 0 01.49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 01-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 01.51-.49z' />
		</svg>
	);
}

export default SvgFingerprint;
