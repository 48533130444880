import * as React from 'react';

function LeaveSearch(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d="M2.275 2.275V8.22225V8.20325V18.1315V2.275ZM4.20325 12.2213H7.739C7.905 11.8179 8.106 11.4426 8.342 11.0953C8.57783 10.7479 8.85008 10.4267 9.15875 10.1315H4.20325V12.2213ZM4.20325 16.2213H7.34825C7.27292 15.8719 7.22575 15.5226 7.20675 15.1733C7.18775 14.8241 7.20092 14.4768 7.24625 14.1315H4.20325V16.2213ZM2.275 20.4065C1.64533 20.4065 1.10875 20.1848 0.66525 19.7413C0.22175 19.2978 0 18.7612 0 18.1315V2.275C0 1.64533 0.22175 1.10875 0.66525 0.66525C1.10875 0.22175 1.64533 0 2.275 0H10.287L16.4065 6.1195V8.72125C16.046 8.56792 15.6752 8.45075 15.294 8.36975C14.9128 8.28875 14.5253 8.23958 14.1315 8.22225V7.275H9.1315V2.275H2.275V18.1315H8.09675C8.35942 18.5873 8.66842 19.01 9.02375 19.3995C9.37892 19.789 9.77442 20.1247 10.2103 20.4065H2.275ZM13.8333 17.2033C14.4944 17.2033 15.0538 16.975 15.5113 16.5185C15.9686 16.062 16.1973 15.5033 16.1973 14.8422C16.1973 14.1811 15.969 13.6218 15.5125 13.1645C15.056 12.707 14.4973 12.4783 13.8363 12.4783C13.1751 12.4783 12.6158 12.7065 12.1585 13.163C11.701 13.6195 11.4723 14.1783 11.4723 14.8393C11.4723 15.5004 11.7005 16.0597 12.157 16.517C12.6135 16.9745 13.1723 17.2033 13.8333 17.2033ZM18.9348 21.538L16.2108 18.8143C15.8608 19.0356 15.4843 19.2016 15.0813 19.3123C14.6781 19.4229 14.2626 19.4783 13.8348 19.4783C12.5466 19.4783 11.4517 19.0274 10.55 18.1258C9.64817 17.2239 9.19725 16.1289 9.19725 14.8408C9.19725 13.5526 9.64817 12.4576 10.55 11.5558C11.4517 10.6541 12.5466 10.2032 13.8348 10.2032C15.1229 10.2032 16.2179 10.6541 17.1197 11.5558C18.0214 12.4576 18.4722 13.5526 18.4722 14.8408C18.4722 15.2686 18.4169 15.6841 18.3062 16.0872C18.1956 16.4902 18.0296 16.8667 17.8083 17.2167L20.532 19.9408L18.9348 21.538Z" />
        </svg>
	);
}

export default LeaveSearch;