import * as React from 'react';

function SvgChangeHistory(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M12 7.77L5.61 18h12.78z' opacity={0.3} />
			<path d='M12 4L2 20h20L12 4zm0 3.77L18.39 18H5.61L12 7.77z' />
		</svg>
	);
}

export default SvgChangeHistory;
