import * as React from 'react';

function SvgSymmetryVertical(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M7 2.5a.5.5 0 00-.939-.24l-6 11A.5.5 0 00.5 14h6a.5.5 0 00.5-.5v-11zm2.376-.484a.5.5 0 01.563.245l6 11A.5.5 0 0115.5 14h-6a.5.5 0 01-.5-.5v-11a.5.5 0 01.376-.484zM10 4.46V13h4.658L10 4.46z' />
		</svg>
	);
}

export default SvgSymmetryVertical;
