import * as React from 'react';

function SvgArrowDownLeftCircleFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M16 8A8 8 0 100 8a8 8 0 0016 0zm-5.904-2.803a.5.5 0 11.707.707L6.707 10h2.768a.5.5 0 010 1H5.5a.5.5 0 01-.5-.5V6.525a.5.5 0 011 0v2.768l4.096-4.096z' />
		</svg>
	);
}

export default SvgArrowDownLeftCircleFill;
