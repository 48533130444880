import * as React from 'react';

function SvgExposureNeg1(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M19 18V5h-.3L14 6.7v1.7l3-1.02V18zM4 11h8v2H4z' />
		</svg>
	);
}

export default SvgExposureNeg1;
