import * as React from 'react';

function SvgNodeMinusFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path
				fillRule='evenodd'
				d='M16 8a5 5 0 01-9.975.5H4A1.5 1.5 0 012.5 10h-1A1.5 1.5 0 010 8.5v-1A1.5 1.5 0 011.5 6h1A1.5 1.5 0 014 7.5h2.025A5 5 0 0116 8zm-2 0a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h5A.5.5 0 0014 8z'
			/>
		</svg>
	);
}

export default SvgNodeMinusFill;
