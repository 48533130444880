import * as React from 'react';

function LeaveCreate(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d="M14.0658 20.4963V17.4963H11.0658V15.2212H14.0658V12.2213H16.3407V15.2212H19.3407V17.4963H16.3407V20.4963H14.0658ZM2.275 18.4065C1.64533 18.4065 1.10875 18.1848 0.66525 17.7413C0.22175 17.2978 0 16.7612 0 16.1315V4.275C0 3.64533 0.22175 3.10875 0.66525 2.66525C1.10875 2.22175 1.64533 2 2.275 2H3.20325V0H5.34075V2H11.0658V0H13.2032V2H14.1315C14.7612 2 15.2978 2.22175 15.7413 2.66525C16.1848 3.10875 16.4065 3.64533 16.4065 4.275V10.357C16.0253 10.2832 15.6462 10.2453 15.269 10.2433C14.8918 10.2413 14.5127 10.2693 14.1315 10.3273V8.20325H2.275V16.1315H9.07775C9.06175 16.5127 9.07875 16.8918 9.12875 17.269C9.17875 17.6462 9.27642 18.0253 9.42175 18.4065H2.275ZM2.275 6.20325H14.1315V4.275H2.275V6.20325Z" />
        </svg>
	);
}

export default LeaveCreate;
