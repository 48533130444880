import * as React from 'react';

function SvgArrowUpRightCircle(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path
				fillRule='evenodd'
				d='M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zM5.854 10.803a.5.5 0 11-.708-.707L9.243 6H6.475a.5.5 0 110-1h3.975a.5.5 0 01.5.5v3.975a.5.5 0 11-1 0V6.707l-4.096 4.096z'
			/>
		</svg>
	);
}

export default SvgArrowUpRightCircle;
