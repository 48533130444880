import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeContextProvider } from './contexts/themeContext';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Toasts, { Toast, ToastContainer } from './components/bootstrap/Toasts';

import '@nosferatu500/react-sortable-tree/style.css';
import '@glideapps/glide-data-grid/dist/index.css';
import './styles/styles.scss';
import './i18n';
// import App from './App';

window.progressbar = null;
const container = document.getElementById('root');
const root = createRoot(container);
const App = React.lazy(() => import('./App'));

root.render(
	<ToastProvider components={{ ToastContainer, Toast }}>
		<Router>
			{/* <React.StrictMode> */}
			<ThemeContextProvider>
				<App />
			</ThemeContextProvider>
			{/* </React.StrictMode> */}
		</Router>
	</ToastProvider>
);
//registerServiceWorker();
