import * as React from 'react';

function LeavePromotion(props) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d="M13.8968 21.044L10.1555 17.3027L11.7467 15.7115L13.8717 17.8365L18.1217 13.5805L19.713 15.2028L13.8968 21.044ZM4.8245 15.544L3.23325 13.9528L4.83325 12.3528L3.23325 10.7528L4.8245 9.1615L6.4245 10.7615L8.0245 9.1615L9.61575 10.7528L8.01575 12.3528L9.61575 13.9528L8.0245 15.544L6.4245 13.944L4.8245 15.544ZM2.275 20.4065C1.64533 20.4065 1.10875 20.1848 0.66525 19.7413C0.22175 19.2978 0 18.7612 0 18.1315V4.275C0 3.64533 0.22175 3.10875 0.66525 2.66525C1.10875 2.22175 1.64533 2 2.275 2H3.20325V0H5.34075V2H13.0658V0H15.2032V2H16.1315C16.7612 2 17.2978 2.22175 17.7413 2.66525C18.1848 3.10875 18.4065 3.64533 18.4065 4.275V10.4458L16.1315 12.7515V8.20325H2.275V18.1315H8.17225L10.4223 20.4065H2.275ZM2.275 6.20325H16.1315V4.275H2.275V6.20325Z" />
        </svg>
    );
}

export default LeavePromotion;