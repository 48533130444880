import * as React from 'react';

function SvgCloudFogFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M3 13.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zm0 2a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zm10.405-9.473a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 12H13a3 3 0 00.405-5.973z' />
		</svg>
	);
}

export default SvgCloudFogFill;
