import * as React from 'react';

function SvgSkipForwardCircleFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M16 8A8 8 0 110 8a8 8 0 0116 0zM4.79 5.093A.5.5 0 004 5.5v5a.5.5 0 00.79.407L7.5 8.972V10.5a.5.5 0 00.79.407L11 8.972V10.5a.5.5 0 001 0v-5a.5.5 0 00-1 0v1.528L8.29 5.093a.5.5 0 00-.79.407v1.528L4.79 5.093z' />
		</svg>
	);
}

export default SvgSkipForwardCircleFill;
