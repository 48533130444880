import * as React from 'react';

function SvgBookmarkXFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path
				fillRule='evenodd'
				d='M2 15.5V2a2 2 0 012-2h8a2 2 0 012 2v13.5a.5.5 0 01-.74.439L8 13.069l-5.26 2.87A.5.5 0 012 15.5zM6.854 5.146a.5.5 0 10-.708.708L7.293 7 6.146 8.146a.5.5 0 10.708.708L8 7.707l1.146 1.147a.5.5 0 10.708-.708L8.707 7l1.147-1.146a.5.5 0 00-.708-.708L8 6.293 6.854 5.146z'
			/>
		</svg>
	);
}

export default SvgBookmarkXFill;
