import * as React from 'react';

function SvgSkipStartCircleFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M16 8A8 8 0 110 8a8 8 0 0116 0zM9.71 5.093L7 7.028V5.5a.5.5 0 00-1 0v5a.5.5 0 001 0V8.972l2.71 1.935a.5.5 0 00.79-.407v-5a.5.5 0 00-.79-.407z' />
		</svg>
	);
}

export default SvgSkipStartCircleFill;
