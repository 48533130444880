import * as React from 'react';

function SvgBug(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M4.355.522a.5.5 0 01.623.333l.291.956A4.979 4.979 0 018 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 11.957.29l-.41 1.352A4.985 4.985 0 0113 6h.5a.5.5 0 00.5-.5V5a.5.5 0 011 0v.5A1.5 1.5 0 0113.5 7H13v1h1.5a.5.5 0 010 1H13v1h.5a1.5 1.5 0 011.5 1.5v.5a.5.5 0 11-1 0v-.5a.5.5 0 00-.5-.5H13a5 5 0 01-10 0h-.5a.5.5 0 00-.5.5v.5a.5.5 0 11-1 0v-.5A1.5 1.5 0 012.5 10H3V9H1.5a.5.5 0 010-1H3V7h-.5A1.5 1.5 0 011 5.5V5a.5.5 0 011 0v.5a.5.5 0 00.5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 01.333-.623zM4 7v4a4 4 0 003.5 3.97V7H4zm4.5 0v7.97A4 4 0 0012 11V7H8.5zM12 6a3.989 3.989 0 00-1.334-2.982A3.983 3.983 0 008 2a3.983 3.983 0 00-2.667 1.018A3.989 3.989 0 004 6h8z' />
		</svg>
	);
}

export default SvgBug;
