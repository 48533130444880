import * as React from 'react';

function SvgCalendar3Week(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M14 0H2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z' />
			<path d='M12 7a1 1 0 100-2 1 1 0 000 2zm-5 3a1 1 0 100-2 1 1 0 000 2zm2-3a1 1 0 100-2 1 1 0 000 2zm-5 3a1 1 0 100-2 1 1 0 000 2z' />
		</svg>
	);
}

export default SvgCalendar3Week;
