import * as React from 'react';

function SvgArrowDownRightCircle(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path
				fillRule='evenodd'
				d='M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zM5.854 5.146a.5.5 0 10-.708.708L9.243 9.95H6.475a.5.5 0 100 1h3.975a.5.5 0 00.5-.5V6.475a.5.5 0 10-1 0v2.768L5.854 5.146z'
			/>
		</svg>
	);
}

export default SvgArrowDownRightCircle;
