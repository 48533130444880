import * as React from 'react';

function SvgDisplayFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M6 12c0 .667-.083 1.167-.25 1.5H5a.5.5 0 000 1h6a.5.5 0 000-1h-.75c-.167-.333-.25-.833-.25-1.5h4c2 0 2-2 2-2V4c0-2-2-2-2-2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h4z' />
		</svg>
	);
}

export default SvgDisplayFill;
