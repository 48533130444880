import * as React from 'react';

function SvgBrightnessAltLowFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M8.5 5.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 6a.5.5 0 110-1 .5.5 0 010 1zM2 11a.5.5 0 101 0 .5.5 0 00-1 0zm10.243-3.536a.5.5 0 11-.707-.707.5.5 0 01.707.707zm-8.486-.707a.5.5 0 10.707.707.5.5 0 00-.707-.707zM8 7a4 4 0 00-4 4 .5.5 0 00.5.5h7a.5.5 0 00.5-.5 4 4 0 00-4-4z' />
		</svg>
	);
}

export default SvgBrightnessAltLowFill;
