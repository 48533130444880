import * as React from 'react';

function SvgCupFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M1 2a1 1 0 011-1h11a1 1 0 011 1v1h.5A1.5 1.5 0 0116 4.5v7a1.5 1.5 0 01-1.5 1.5h-.55a2.5 2.5 0 01-2.45 2h-8A2.5 2.5 0 011 12.5V2zm13 10h.5a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5H14v8z' />
		</svg>
	);
}

export default SvgCupFill;
