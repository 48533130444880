import * as React from 'react';

function SvgSportsBasketball(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M8.93 11H11V4.07c-1.73.22-3.29.99-4.5 2.13A7.99 7.99 0 018.93 11zM19.93 11a8.12 8.12 0 00-1.16-3.25c-.87.87-1.47 1.99-1.68 3.25h2.84zM5.23 7.75A8.12 8.12 0 004.07 11h2.84a5.972 5.972 0 00-1.68-3.25zM4.07 13a8.12 8.12 0 001.16 3.25c.87-.87 1.47-1.99 1.68-3.25H4.07zM6.51 17.79A7.988 7.988 0 0011 19.93V13H8.93a7.952 7.952 0 01-2.42 4.79zM17.5 6.2A7.97 7.97 0 0013 4.07V11h2.07a7.99 7.99 0 012.43-4.8zM18.77 16.25A7.78 7.78 0 0019.93 13h-2.84c.21 1.26.81 2.38 1.68 3.25zM13 13v6.93c1.73-.22 3.29-1 4.49-2.14A7.952 7.952 0 0115.07 13H13z'
				opacity={0.3}
			/>
			<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM5.23 7.75C6.1 8.62 6.7 9.74 6.91 11H4.07a8.12 8.12 0 011.16-3.25zM4.07 13h2.84a5.972 5.972 0 01-1.68 3.25A8.12 8.12 0 014.07 13zM11 19.93c-1.73-.22-3.29-1-4.49-2.14A7.952 7.952 0 008.93 13H11v6.93zM11 11H8.93A7.99 7.99 0 006.5 6.2 8.035 8.035 0 0111 4.07V11zm8.93 0h-2.84c.21-1.26.81-2.38 1.68-3.25.6.97 1.01 2.07 1.16 3.25zM13 4.07c1.73.22 3.29.99 4.5 2.13a7.99 7.99 0 00-2.43 4.8H13V4.07zm0 15.86V13h2.07a8.006 8.006 0 002.42 4.79A7.988 7.988 0 0113 19.93zm5.77-3.68A6.004 6.004 0 0117.09 13h2.84a8.12 8.12 0 01-1.16 3.25z' />
		</svg>
	);
}

export default SvgSportsBasketball;
