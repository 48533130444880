import * as React from 'react';

function SvgCaretLeftSquareFill(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm10.5 10V4a.5.5 0 00-.832-.374l-4.5 4a.5.5 0 000 .748l4.5 4A.5.5 0 0010.5 12z' />
		</svg>
	);
}

export default SvgCaretLeftSquareFill;
