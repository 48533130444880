import * as React from 'react';

function SvgWhatshot(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 24 24' width='1em' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M16.11 6.77c-.53 2.6-2.62 4.43-5.28 4.43-1.56 0-2.96-.62-3.97-1.63C6.3 10.96 6 12.47 6 14c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.56-.66-5.03-1.89-7.23zm-4.22 11.22c-1.37 0-2.49-1.08-2.49-2.42 0-1.25.81-2.13 2.17-2.41 1.37-.28 2.78-.93 3.57-1.99.3 1 .46 2.05.46 3.12 0 2.04-1.66 3.7-3.71 3.7z'
				opacity={0.3}
			/>
			<path d='M11.57 13.16c-1.36.28-2.17 1.16-2.17 2.41 0 1.34 1.11 2.42 2.49 2.42 2.05 0 3.71-1.66 3.71-3.71 0-1.07-.15-2.12-.46-3.12-.79 1.07-2.2 1.72-3.57 2zM13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM12 20c-3.31 0-6-2.69-6-6 0-1.53.3-3.04.86-4.43a5.582 5.582 0 003.97 1.63c2.66 0 4.75-1.83 5.28-4.43A14.77 14.77 0 0118 14c0 3.31-2.69 6-6 6z' />
		</svg>
	);
}

export default SvgWhatshot;
