import * as React from 'react';

function SvgCone(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' className='svg-icon' viewBox='0 0 16 16' {...props}>
			<path d='M7.03 1.88c.252-1.01 1.688-1.01 1.94 0l2.905 11.62H14a.5.5 0 010 1H2a.5.5 0 010-1h2.125L7.03 1.88z' />
		</svg>
	);
}

export default SvgCone;
